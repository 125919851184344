import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  categoryIconsInitialized = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private http: HttpClient,
  ) {}

  initializeCategoryIcons(categories: any): Promise<void> {
    const FALLBACK_PATH = 'assets/img/category-icons/all-items.svg';
    let categoryIconsInitializedCount = 0;

    return new Promise((resolve, reject) => {
      if (this.categoryIconsInitialized) {
        resolve();
      }

      categories.forEach((category: any) => {
        const PATH = `assets/img/category-icons/${category.name}.svg`;

        this.registerCategoryIcon(category.name, PATH, FALLBACK_PATH)
          .then(() => {
            categoryIconsInitializedCount++;
            if (categoryIconsInitializedCount === categories.length) {
              this.categoryIconsInitialized = true;
              /* All categories icons created */
              resolve();
            }
          })
          .catch(() => {
            this.categoryIconsInitialized = false;
            /* Error occurred */
            reject();
          });
      });
    });
  }

  registerCategoryIcon(name: string, path: string, fallbackPath?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.matIconRegistry.getNamedSvgIcon(name).subscribe({
        next: () => {
          /* Icon already exists in directory */
          resolve();
        },
        error: () => {
          this.http.head(path, { responseType: 'blob' }).subscribe({
            next: (res) => {
              if (res.type === 'image/svg+xml') {
                /* Icon will be registered using its correct path */
                this.addIconToRegistry(name, path);
                resolve();
              } else if (fallbackPath) {
                /* Icon will be registered using fallback path */
                this.addIconToRegistry(name, fallbackPath);
                resolve();
              } else {
                /* Icon couldn't be created */
                reject();
              }
            },
            error: () => {
              if (fallbackPath) {
                /* Icon will be registered using fallback path */
                this.addIconToRegistry(name, fallbackPath);
                resolve();
              } else {
                /* Icon couldn't be created */
                reject();
              }
            },
          });
        },
      });
    });
  }

  addIconToRegistry(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
