import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  constructor(private http: HttpClient) {}

  getAWBFromBosta(formData: any): Observable<any> {
    return this.http.post(API_URLS.GET_AWB_FROM_BOSTA_URL, formData);
  }

  trackPackageFromBosta(formData: any): Observable<any> {
    return this.http.post(API_URLS.TRACK_PACKAGE_FROM_BOSTA_URL, formData);
  }

  trackPackageFromDreevo(formData: any): Observable<any> {
    return this.http.post(API_URLS.TRACK_PACKAGE_FROM_DREEVO_URL, formData);
  }

  trackShipmentFromAramex(formData: any): Observable<any> {
    return this.http.post(API_URLS.TRACK_SHIPMENT_FROM_ARAMEX_URL, formData);
  }

  trackShipmentFromVHubs(formData: any): Observable<any> {
    return this.http.post(API_URLS.TRACK_SHIPMENT_FROM_VHUBS_URL, formData);
  }
}
