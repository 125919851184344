import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class OrderIssuesService {
  constructor(private http: HttpClient) {}

  addOrderRefund(orderIssues: any): Observable<any> {
    return this.http.post(API_URLS.ADD_ORDER_REFUND_URL, orderIssues);
  }

  addOrderReplacement(orderIssues: any): Observable<any> {
    return this.http.post(API_URLS.ADD_ORDER_REPLACEMENT_URL, orderIssues);
  }

  addOrderCompletion(orderIssues: any): Observable<any> {
    return this.http.post(API_URLS.ADD_ORDER_COMPLETION_URL, orderIssues);
  }

  addIssueAttachment(formData: any) {
    return this.http.post(API_URLS.ADD_ISSUE_ATTACHMENT_URL, formData);
  }
}
